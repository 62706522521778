.button {
  @apply inline-flex items-center font-semibold border rounded-lg px-3 flex-shrink-0;
}

.button + .button {
  @apply ml-2;
}

.button--primary {
  @apply text-white border-green bg-green hover:bg-green-hover shadow-flowfi;
}

.button--secondary {
  @apply text-black bg-white border-gray-lighter hover:bg-gray-hover shadow-flowfi;
}

.button--tertiary {
  @apply text-gray-dark bg-gray-lighter border-gray-lighter hover:bg-gray-light shadow-flowfi;
}

.button--quaternary {
  @apply text-green bg-green-lighter border-gray-lighter hover:border-green shadow-flowfi;
}

.button--danger {
  @apply text-red bg-white border-gray-lighter hover:bg-red-light shadow-flowfi;
}

.button--full-width {
  @apply w-full;
}

.button--no-wrap {
  @apply whitespace-nowrap;
}

.button--disabled,
.button[disabled] {
  @apply opacity-50 pointer-events-none;
}

.button--primary.button--disabled {
  @apply bg-green;
}

.button--secondary.button--disabled {
  @apply bg-white;
}

.button--tertiary.button--disabled {
  @apply bg-gray-lighter;
}

.button--xl {
  @apply h-14 text-base;
}

.button--lg {
  @apply h-12 text-base;
}

.button--md {
  @apply h-10 text-sm;
}

.button--sm {
  @apply h-6 rounded text-xs;
}

.button--with-icon img {
  @apply mr-2;

  width: 16px;
}

.button--icon-only {
  @apply justify-center p-0;
}

.button--xl.button--icon-only {
  @apply w-14 h-14;
}

.button--xl.button--icon-only img {
  width: 40px;
}

.button--lg.button--icon-only {
  @apply w-12 h-12;
}

.button--lg.button--icon-only img {
  width: 26px;
}

.button--md.button--icon-only {
  @apply w-10 h-10;
}

.button--md.button--icon-only img {
  width: 24px;
}

.button--sm.button--icon-only {
  @apply w-6 h-6;
}

.button--sm.button--icon-only img {
  width: 18px;
}
