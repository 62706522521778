.dropdown {
  @apply w-auto flex items-center relative;
}

.dropdown__trigger {
  @apply w-full appearance-none flex flex-row items-center;
}

.dropdown__trigger--text {
  @apply w-auto h-auto bg-transparent;

  font-size: inherit;
}

.dropdown__trigger--select {
  @apply pl-2 py-2 border rounded-lg border-gray-lighter shadow-flowfi;
}

.dropdown__icon {
  @apply mr-2;
}

.dropdown__right-icon {
  @apply ml-2;
}

.dropdown__items {
  @apply p-2 absolute top-full rounded-lg bg-white border border-gray-lighter shadow-flowfi;

  min-width: 100%;
  z-index: 100;
}

.dropdown__items--no-padding {
  @apply p-0;
}

.dropdown__items--select {
  top: calc(100% + 4px);
}

.dropdown__items--left {
  @apply right-0;
}

.dropdown__items--right {
  @apply left-0;
}

.dropdown__items--center {
  /* calc this out */
}

.dropdown__button {
  @apply flex flex-row items-center appearance-none w-full text-white p-4 hover:underline;
}

.dropdown__button--active {
  color: #32dfc5;
}

.dropdown__button-icon {
  @apply pr-2 flex-shrink-0;
}

.dropdown__option {
  @apply my-0.5 px-2 py-1 flex flex-row items-center justify-between text-sm rounded hover:bg-gray-hover;
}

.dropdown__option a {
  @apply text-black hover:text-black hover:no-underline;
}
