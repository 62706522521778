.simple-select {
  @apply py-1 pl-2 pr-6 appearance-none bg-no-repeat overflow-hidden overflow-ellipsis whitespace-nowrap;

  background-position: calc(100% - 3px) center;
  background-size: 1.5rem;
}

.simple-select--full-width {
  @apply w-full;
}

/* TODO: there is probably a better way to specify which icon to use, I'll figure it out later **/

.simple-select--user {
  background-image: url("../../assets/icons/green/user.svg");
}

.simple-select--label {
  background-image: url("../../assets/icons/green/label.svg");
}

.simple-select--arrow {
  background-image: url("../../assets/icons/green/arrow-down-lg.svg");
}
