.workbench-logo {
  @apply flex flex-row items-center mt-2 mb-8 border-transparent border-l-2 font-semibold text-white text-xl;
}

.workbench-logo--marginless {
  @apply m-0;
}

.workbench-logo--dark {
  @apply text-black;
}

.workbench-logo__icon {
  @apply flex p-2 justify-center;

  width: 70px;
}

.workbench-logo--dark .workbench-logo__icon {
  /* this state needs some love once we're more square */
  width: 50px;
}

.workbench-logo__label {
  @apply p-2 pl-4;
}
