@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap";
.flowfi-grid {
  display: grid;
}

.flowfi-login {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 60vw 40vw;
  gap: 0;
  margin-left: auto;
  margin-right: auto;
}

.flowfi-base {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 250px 1fr;
  overflow: hidden;
}

.flowfi-two {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 7fr 3fr;
}

.flowfi-three {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr 4fr 2fr;
}

.flowfi-condensed {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 72px 1fr;
}

.flowfi-bare {
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  display: flex;
}

.responsive-grid-four {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  display: grid;
}

@media (min-width: 1024px) {
  .responsive-grid-four {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.dropdown {
  width: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.dropdown__trigger {
  width: 100%;
  appearance: none;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.dropdown__trigger--text {
  height: auto;
  width: auto;
  font-size: inherit;
  background-color: #0000;
}

.dropdown__trigger--select {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
}

.dropdown__icon {
  margin-right: .5rem;
}

.dropdown__right-icon {
  margin-left: .5rem;
}

.dropdown__items {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  min-width: 100%;
  z-index: 100;
  border-radius: .5rem;
  padding: .5rem;
  position: absolute;
  top: 100%;
}

.dropdown__items--no-padding {
  padding: 0;
}

.dropdown__items--select {
  top: calc(100% + 4px);
}

.dropdown__items--left {
  right: 0;
}

.dropdown__items--right {
  left: 0;
}

.dropdown__button {
  width: 100%;
  appearance: none;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.dropdown__button:hover {
  text-decoration: underline;
}

.dropdown__button--active {
  color: #32dfc5;
}

.dropdown__button-icon {
  flex-shrink: 0;
  padding-right: .5rem;
}

.dropdown__option {
  border-radius: .25rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: .125rem;
  margin-bottom: .125rem;
  display: flex;
}

.dropdown__option:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.dropdown__option {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.dropdown__option a {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
}

.dropdown__option a:hover {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  text-decoration: none;
}

.user-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 190, 198, var(--tw-bg-opacity) );
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.user-icon--sm {
  height: 1.5rem;
  width: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.user-icon--md {
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.user-icon--lg {
  height: 3rem;
  width: 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.user-icon--xl {
  height: 4rem;
  width: 4rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.user-menu {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.user-menu__trigger {
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.scroll-container {
  -webkit-overflow-scrolling: touch;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll-container--full-height {
  height: 100%;
}

.scroll-container--no-padding {
  padding: 0;
}

.button {
  border-width: 1px;
  border-radius: .5rem;
  flex-shrink: 0;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  font-weight: 600;
  display: inline-flex;
}

.button + .button {
  margin-left: .5rem;
}

.button--primary {
  --tw-border-opacity: 1;
  border-color: rgba(2, 143, 118, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(2, 143, 118, var(--tw-bg-opacity) );
}

.button--primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 117, 97, var(--tw-bg-opacity) );
}

.button--primary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button--secondary {
  --tw-border-opacity: 1;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.button--secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.button--secondary {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button--tertiary {
  --tw-border-opacity: 1;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.button--tertiary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 190, 198, var(--tw-bg-opacity) );
}

.button--tertiary {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button--quaternary {
  --tw-border-opacity: 1;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
}

.button--quaternary:hover {
  --tw-border-opacity: 1;
  border-color: rgba(2, 143, 118, var(--tw-border-opacity) );
}

.button--quaternary {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 244, 241, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(2, 143, 118, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button--danger {
  --tw-border-opacity: 1;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.button--danger:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 209, 210, var(--tw-bg-opacity) );
}

.button--danger {
  --tw-text-opacity: 1;
  color: rgba(255, 27, 28, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.button--full-width {
  width: 100%;
}

.button--no-wrap {
  white-space: nowrap;
}

.button--disabled, .button[disabled] {
  pointer-events: none;
  opacity: .5;
}

.button--primary.button--disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 143, 118, var(--tw-bg-opacity) );
}

.button--secondary.button--disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.button--tertiary.button--disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.button--xl {
  height: 3.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.button--lg {
  height: 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.button--md {
  height: 2.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.button--sm {
  height: 1.5rem;
  border-radius: .25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.button--with-icon img {
  width: 16px;
  margin-right: .5rem;
}

.button--icon-only {
  justify-content: center;
  padding: 0;
}

.button--xl.button--icon-only {
  height: 3.5rem;
  width: 3.5rem;
}

.button--xl.button--icon-only img {
  width: 40px;
}

.button--lg.button--icon-only {
  height: 3rem;
  width: 3rem;
}

.button--lg.button--icon-only img {
  width: 26px;
}

.button--md.button--icon-only {
  height: 2.5rem;
  width: 2.5rem;
}

.button--md.button--icon-only img {
  width: 24px;
}

.button--sm.button--icon-only {
  height: 1.5rem;
  width: 1.5rem;
}

.button--sm.button--icon-only img {
  width: 18px;
}

.badge {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
  border-radius: .5rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

.company-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 190, 198, var(--tw-bg-opacity) );
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  border-radius: 9999px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.company-icon img {
  height: 100%;
  width: 100%;
}

.company-icon--xs {
  height: 1.25rem;
  width: 1.25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.company-icon--sm {
  height: 1.5rem;
  width: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.company-icon--md {
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.company-icon--lg {
  height: 3rem;
  width: 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.company-icon--xl {
  height: 4rem;
  width: 4rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.company-icon--2xl {
  height: 5rem;
  width: 5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.company-selector__trigger {
  min-width: 250px;
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.company-selector__trigger span {
  padding-left: .5rem;
}

.company-selector__label {
  --tw-text-opacity: 1;
  color: rgba(185, 190, 198, var(--tw-text-opacity) );
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.company-selector__dropdown {
  padding: .5rem;
}

.company-selector__dropdown-items {
  min-width: 250px;
}

.company-selector__dropdown-item {
  border-radius: .25rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: .125rem;
  margin-bottom: .125rem;
  display: flex;
}

.company-selector__dropdown-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.company-selector__dropdown-item {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.company-selector__portfolio-link {
  margin-left: .125rem;
  margin-right: .125rem;
}

.top-bar {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  flex-direction: row;
  padding: 1rem;
  display: flex;
}

.svg {
  background-color: #0000;
}

.svg-icon {
  opacity: .9;
}

.svg--gray {
  filter: grayscale(65%) hue-rotate(30deg) saturate(338%) brightness(88%) contrast(86%);
}

.svg--blue {
  filter: brightness(0) saturate() invert(42%) sepia(82%) saturate(2092%) hue-rotate(199deg) brightness(92%) contrast(85%);
}

.svg--green {
  filter: sepia() hue-rotate(116deg) saturate(340%) brightness(70%) contrast(91%);
}

.svg--red {
  filter: brightness(0) saturate() invert(32%) sepia(73%) saturate(6362%) hue-rotate(347deg) brightness(125%) contrast(130%);
}

.svg--orange {
  filter: brightness(0) saturate() invert(58%) sepia(22%) saturate(6369%) hue-rotate(8deg) brightness(104%) contrast(99%);
}

.svg--yellow {
  filter: brightness(0) saturate() invert(70%) sepia(84%) saturate(1260%) hue-rotate(360deg) brightness(104%) contrast(104%);
}

.workbench-logo {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  border-color: #0000;
  border-left-width: 2px;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.workbench-logo--marginless {
  margin: 0;
}

.workbench-logo--dark {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
}

.workbench-logo__icon {
  width: 70px;
  justify-content: center;
  padding: .5rem;
  display: flex;
}

.workbench-logo--dark .workbench-logo__icon {
  width: 50px;
}

.workbench-logo__label {
  padding: .5rem .5rem .5rem 1rem;
}

.side-nav-item {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  height: 56px;
  border-color: #0000;
  border-left-width: 2px;
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  display: flex;
}

.side-nav-item--active {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-color: rgba(2, 143, 118, var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: rgba(2, 143, 118, var(--tw-text-opacity) );
}

.side-nav-item__icon {
  height: 100%;
  width: 70px;
  justify-content: center;
  align-items: center;
  padding-left: calc(.5rem - 2px);
  padding-right: .5rem;
  display: flex;
}

.side-nav-item__icon img {
  width: 26px;
  height: 26px;
  display: block;
}

.side-nav-item--active .side-nav-item__icon {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 49, 56, var(--tw-bg-opacity) );
}

.side-nav-item__label {
  height: 100%;
  align-items: center;
  padding-left: 1rem;
  padding-right: .5rem;
  display: flex;
}

button.side-nav-item:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 117, 97, var(--tw-text-opacity) );
}

.flowfi-sidebar {
  height: 100vh;
  --tw-bg-opacity: 1;
  background-color: rgba(11, 15, 29, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  background-image: url("gray-tile.16fc88e9.png");
  background-position: 72px 0;
  background-repeat: repeat-y;
  flex-direction: column;
  display: flex;
}

.flowfi-sidebar--collapsed {
  background-image: none;
}

.flowfi-sidebar__mode-toggle {
  align-self: flex-end;
}



.learn-more {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin-left: .5rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
  position: relative;
}

.learn-more__tip {
  pointer-events: none;
  opacity: 0;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  left: 80%;
}

.learn-more:hover > .learn-more__tip {
  pointer-events: auto;
  opacity: 1;
  left: 100%;
}

.learn-more__link {
  --tw-bg-opacity: 1;
  background-color: rgba(11, 15, 29, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  border-radius: .375rem;
  margin-left: .5rem;
  padding: .5rem;
  display: block;
}

.learn-more__link:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  text-decoration: underline;
}

.pane {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: .5rem;
}

.account-block-card {
  cursor: pointer;
}

.account-block-card__inner {
  padding: 1rem;
}

.account-block-card__heading {
  align-items: center;
  display: flex;
}

.account-block-card__icon {
  height: 3.5rem;
  width: 3.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(230, 244, 241, var(--tw-bg-opacity) );
  border-radius: .375rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

.account-block-card__icon--red {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 232, 232, var(--tw-bg-opacity) );
}

.account-block-card__name {
  min-width: 0;
  overflow-wrap: break-word;
}

.account-block-card__divider {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.account-block-card__insights {
  flex-direction: column;
  gap: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.account-block-card-insight {
  justify-content: space-between;
  align-items: flex-end;
  gap: .5rem;
  display: flex;
}

.account-block-card-insight__label {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
}

.account-tags__header {
  vertical-align: middle;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

input[type="text"].flowfi-datepicker {
  background-image: url("calendar.5415f6a6.svg");
  background-position: calc(100% - .5rem);
  background-repeat: no-repeat;
  padding-right: 2.25rem;
}

.flowfi-datepicker__popper {
  z-index: 50 !important;
}

.react-datepicker {
  border: 1px solid var(--bg-gray-lighter) !important;
  border-radius: .5rem !important;
  overflow: hidden !important;
  box-shadow: 0 2px 3px #7b838e1a !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__navigation-icon {
  width: 32px !important;
  display: block !important;
  top: 0 !important;
}

.react-datepicker__navigation--previous {
  left: 0 !important;
}

.react-datepicker__navigation-icon--previous, .react-datepicker__navigation--next {
  right: 0 !important;
}

.react-datepicker__navigation-icon--next {
  left: 0 !important;
}

.react-datepicker__navigation-icon--previous, .react-datepicker__navigation-icon--next {
  height: 32px !important;
}

.react-datepicker__navigation-icon--previous:before, .react-datepicker__navigation-icon--next:before {
  width: 32px !important;
  height: 32px !important;
  background-repeat: no-repeat !important;
  border: none !important;
}

.react-datepicker__navigation-icon--previous:before {
  background-image: url("arrow-left-sm.a5f94b71.svg") !important;
  background-position: 50% !important;
  top: 0 !important;
  right: 0 !important;
  transform: none !important;
}

.react-datepicker__navigation-icon--next:before {
  background-image: url("arrow-right-sm.9120a171.svg") !important;
  background-position: 50% !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.react-datepicker__header {
  background: var(--white) !important;
  border-bottom: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.react-datepicker__day-name {
  color: var(--bg-gray-dark) !important;
}

.react-datepicker__day--selected {
  color: #fff !important;
  background-color: var(--bg-green-default) !important;
}

.react-datepicker__day--outside-month {
  color: var(--bg-gray-light) !important;
}

.react-datepicker__month {
  margin-top: 0 !important;
}

.flowfi-datepicker__label--left + .react-datepicker-wrapper input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.flowfi-datepicker__wrapper--label-left {
  flex-grow: 1;
  display: flex;
}

.flowfi-datepicker__label--left {
  --tw-border-opacity: 1;
  border-width: 1px 0 1px 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  align-items: center;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.drawer {
  z-index: 20;
  height: 100vh;
  width: 33.3333%;
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  transition: transform .3s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
}

.drawer--right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawer--left {
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.drawer--open {
  transform: translateX(0);
}

.drawer__close {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.drawer__content {
  height: 100%;
  flex-direction: column;
  padding: 3rem 1.5rem 2rem;
  display: flex;
}

.drawer__content--full-height {
  height: 100%;
}

.drawer-overlay {
  z-index: 10;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  background-color: #0000;
  transition: background-color .5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.drawer-overlay--visible {
  pointer-events: all;
  background-color: #0b0f1d40;
  display: block;
}

.toast {
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.toast__body {
  flex-direction: column;
  display: flex;
}

.toast__icon {
  align-items: center;
  display: flex;
}

.toast__message {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  font-size: .875rem;
  line-height: 1.5rem;
}

.load-transactions {
  padding: 1.5rem;
}

.load-transactions__header {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.load-transactions__form {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.load-transactions__date-pickers {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.load-transactions__drawer {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.load-transactions__drawer-insights {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.load-transaction__break {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.categorization__header {
  vertical-align: middle;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.merchant-autocomplete__last-row {
  justify-content: space-between;
  gap: .5rem;
  display: flex;
}

.merchant-selector {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.merchant-selector__input-wrapper {
  width: 100%;
}

.line-item-summary {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 242, 251, var(--tw-bg-opacity) );
  border-radius: .5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.line-item-summary dl {
  margin: 0;
}

.line-item-summary dt, .line-item-summary dd {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.line-item-summary dt {
  --tw-text-opacity: 1;
  color: rgba(60, 126, 219, var(--tw-text-opacity) );
  font-weight: 700;
}

.rule-form__header {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.add-condition, .reset-tags {
  --tw-text-opacity: 1;
  color: rgba(60, 126, 219, var(--tw-text-opacity) );
  font-weight: 500;
}

.reset-tags {
  margin-left: auto;
}

.rule-form__row {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.rule-form__row--flex {
  flex-direction: row;
  display: flex;
}

.rule-form__radios {
  flex-direction: row;
  gap: 2rem;
  display: flex;
}

.rule-condition__form {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.rule-condition__form-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.rule-condition__form-message {
  --tw-text-opacity: 1;
  color: rgba(255, 27, 28, var(--tw-text-opacity) );
  font-size: .75rem;
  line-height: 1rem;
}

.rule-condition__label-wrap {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.rule-condition__label-wrap label {
  margin: 0;
}

.rule-condition__type {
  min-width: 100px;
}

.rule-condition__select {
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: .75rem;
  line-height: 1rem;
}

.rule-condition__argument {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

input[type="text"].rule-condition__argument--invalid, input[type="number"].rule-condition__argument--invalid {
  --tw-border-opacity: 1;
  border-color: rgba(255, 27, 28, var(--tw-border-opacity) );
  outline-color: red;
}

.rule-form__line-item {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 229, 248, var(--tw-bg-opacity) );
  border-radius: .5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.rule-form__line-item-info {
  --tw-text-opacity: 1;
  color: rgba(60, 126, 219, var(--tw-text-opacity) );
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  font-weight: 700;
  display: flex;
}

.select-label {
  font-size: .875rem;
  line-height: 1.25rem;
}

.select-options {
  margin: .5rem 0;
}

.select-item {
  cursor: pointer;
}

.select-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.select-item--selected {
  --tw-text-opacity: 1;
  color: rgba(2, 117, 97, var(--tw-text-opacity) );
}

.select-addon {
  margin-top: .5rem;
}

.table-select {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.table-paginator__button {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table-paginator__button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.table-paginator__button {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  padding-left: .75rem;
  padding-right: .75rem;
}

.table-paginator__button:nth-child(n+2) {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
}

.table-paginator__button--active {
  --tw-text-opacity: 1;
  color: rgba(2, 143, 118, var(--tw-text-opacity) );
  font-weight: 700;
}

.paginator__pages {
  padding: 0;
  display: flex;
}

.rules-page-header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

input[type="text"].rule-filter {
  width: 250px;
  margin-left: auto;
}

.rule-condition {
  line-height: 1.5rem;
}

.rule-condition__operator {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
}

.rules-table__conditions {
  max-width: 350px;
}

.rules-table__controls {
  text-align: right;
  min-width: 230px;
}

.view-empty {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.rule-suggestions {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.rule-suggestions__buttons {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  flex-direction: row;
  padding: 1rem;
  display: flex;
}

.loading-background {
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: #ffffffb3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.google-login-button:disabled {
  cursor: not-allowed;
}

.login-column {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.login-header {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.login-title {
  margin-bottom: 2rem;
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 80px;
}

.login-text {
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
}

.login__image {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(204, 233, 228, var(--tw-bg-opacity) );
  background-image: url("login-image.37baf370.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 80%;
  border-radius: .5rem;
}

.portfolio-tile {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1.5rem;
}

.portfolio-tile h3, .portfolio-tile h4 {
  overflow-wrap: break-word;
  margin-right: 1rem;
  overflow: hidden;
}

.portfolio-tile__upper, .portfolio-tile__lower {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.portfolio-tile__upper {
  padding-bottom: 1rem;
}

.portfolio-tile__lower {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  padding-top: 1rem;
}

.portfolio-tab__controls {
  flex-direction: row;
  margin-bottom: 2rem;
  display: flex;
}

input[type="text"].portfolio-tab__filter {
  width: 250px;
  margin-left: auto;
}

.nav-tabs {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
}

.nav-tab {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  border-color: #0000;
  border-bottom-width: 2px;
  margin-right: 1rem;
  padding-bottom: 1.25rem;
  font-weight: 600;
  text-decoration: none;
  display: block;
  position: relative;
}

.nav-tab:hover {
  text-decoration: none;
}

.nav-tab {
  top: 1px;
}

.nav-tab--active {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgba(2, 117, 97, var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
}

.portfolio__header {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: .75rem 1rem;
  display: flex;
}

.portfolio-content {
  width: 83.3333%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.back-button {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  flex-direction: row;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  display: flex;
}

.back-button img {
  margin-right: .5rem;
}

.company-profile {
  grid-template-columns: 3fr 7fr;
  gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

.company-profile__meta {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.company-profile__logo {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  border-radius: .25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  display: flex;
}

.company-profile__name {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.company-profile__email {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  font-size: .875rem;
  line-height: 1.25rem;
  display: inline-flex;
}

.company-profile__email img {
  width: 1rem;
  margin-right: .25rem;
}

.company-profile__form {
  margin: 1.5rem;
}

.company-profile__form h2 {
  margin-bottom: 1.5rem;
}

.company-profile__form-row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.company-profile__form-row input {
  width: 100%;
}

.company-profile__form-row:first-of-type {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
}

.company-profile__form-buttons {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  justify-content: space-between;
  margin-top: .5rem;
  padding-top: 1.5rem;
  display: flex;
}

.company-homepage {
  grid-template-columns: minmax(0, 7fr) 3fr;
  gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

.company-profile-info-box {
  font-size: .875rem;
  line-height: 1.25rem;
}

.company-profile-info-box__header {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  border-radius: .375rem;
  grid-template-columns: 3fr 7fr;
  gap: .5rem;
  padding: .75rem;
  display: grid;
}

.company-profile__meta + .button {
  margin-top: .5rem;
}

.company-profile-info-box__header-details {
  align-items: center;
  display: flex;
}

.company-profile-info-box__row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: .5rem;
  margin: .25rem;
  padding: .5rem;
  display: grid;
}

.company-profile-info-box__row .text-left {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
}

.subheader-row {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.tag {
  white-space: nowrap;
  border-radius: .5rem;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

.tag--global {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 233, 228, var(--tw-bg-opacity) );
}

.tag--local {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.tag--selected {
  outline: 2px solid var(--bg-yellow-default);
}

.tags {
  flex-direction: row;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.tags--show-more {
  flex-wrap: wrap;
}

.tags--edit {
  overflow: visible;
}

.tags__form {
  flex-grow: 1;
}

.tags .tags__input {
  width: 100%;
  outline-offset: 2px;
  outline: 2px solid #0000;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tags__truncate-button {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgba(60, 126, 219, var(--tw-text-opacity) );
  font-size: .75rem;
  line-height: 1rem;
}

.tags__tag-wrapper {
  visibility: hidden;
}

.tags__tag-wrapper--visible {
  visibility: visible;
}

.account-tags-block__header {
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.account-tags-block__header-count {
  --tw-text-opacity: 1;
  color: rgba(241, 143, 1, var(--tw-text-opacity) );
}

.account-tags-block__header-controls {
  gap: .5rem;
  display: flex;
}

input[type="text"].account-tags-block__search {
  width: 250px;
}

.account-tags-block__list {
  flex-direction: column;
  gap: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.account-tags-block__heading {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.account-tags-block__heading-col-account-name {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.account-tags-block__row {
  border-radius: .75rem;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.account-tags-block__edit-row {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.account-tags-block__row:not(.account-tags-block__heading):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.account-tags-block__row > * {
  width: 100%;
}

.account-tags-block__col-account-name {
  max-width: 25%;
  min-width: 25%;
}

.account-tags-block__col-account-type {
  max-width: 20%;
  min-width: 20%;
}

.account-tags-block__col-buttons {
  max-width: 100px;
  justify-content: flex-end;
  display: flex;
}

.account-tags-block__save-cancel {
  justify-content: flex-end;
  display: flex;
}

.account-tags-block__footer {
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  margin-top: 2rem;
  display: flex;
}

.accounts-autocomplete {
  width: 20rem;
}

.uncat-tags__input {
  max-width: 20rem;
  height: 2.5rem !important;
}

.uncat-tags__assign {
  justify-content: flex-end;
  display: flex;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  z-index: 1;
  background-color: #fff0;
  border-left: .1px solid #0000;
  position: relative;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  text-align: left;
  left: 0;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  text-align: right;
  right: 0;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  width: 100%;
  z-index: 1;
  position: absolute;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  white-space: pre-wrap;
  position: relative;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  text-align: right;
  width: 30px;
  position: absolute;
  left: -36px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  text-align: left;
  width: 30px;
  position: absolute;
  right: -36px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1, lower-alpha) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2, lower-roman) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4, lower-alpha) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.rich-text {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
}

.rich-text__toolbar {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  flex-direction: row;
  display: flex;
}

.rich-text__controls {
  flex-direction: row;
  margin: .25rem;
  display: flex;
}

.rich-text__controls + .rich-text__controls {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
}

.rich-text__button {
  border-width: 1px;
  border-color: #0000;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  margin-left: .125rem;
  margin-right: .125rem;
  display: flex;
}

.rich-text__button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.rich-text__button {
  padding: .25rem;
}

.rich-text__button--active {
  --tw-border-opacity: 1;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
}

.rich-text__button--disabled {
  pointer-events: none;
  opacity: .5;
}

.rich-text__editor {
  padding: .5rem;
}

.rich-text__editor blockquote, .rich-text-output blockquote {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
}

.rich-text-output ol, .rich-text-output ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.rich-text-output ol {
  list-style-type: decimal;
}

.rich-text-output ul {
  list-style-type: disc;
}

.simple-select {
  appearance: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-position: calc(100% - 3px);
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding: .25rem 1.5rem .25rem .5rem;
  overflow: hidden;
}

.simple-select--full-width {
  width: 100%;
}

.simple-select--user {
  background-image: url("user.bdcb8ed9.svg");
}

.simple-select--label {
  background-image: url("label.6c0c556d.svg");
}

.simple-select--arrow {
  background-image: url("arrow-down-lg.c73597e4.svg");
}

.action-items__create-trigger {
  height: 3rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(2, 143, 118, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(2, 143, 118, var(--tw-bg-opacity) );
  border-radius: .5rem;
  flex-shrink: 0;
  align-items: center;
  display: inline-flex;
}

.action-items__create-trigger:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 117, 97, var(--tw-bg-opacity) );
}

.action-items__create-trigger {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.action-items__tabs {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
}

.action-items__tab {
  appearance: none;
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  border-color: #0000;
  border-bottom-width: 2px;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
  padding-bottom: .5rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
  position: relative;
}

.action-items__tab:hover {
  text-decoration: none;
}

.action-items__tab {
  top: 1px;
}

.action-items__tab--active {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgba(2, 117, 97, var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
}

.action-items-tab__pip {
  height: .5rem;
  width: .5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(185, 190, 198, var(--tw-bg-opacity) );
  border-radius: 9999px;
  margin-left: .5rem;
}

.action-items-tab__pip--green {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 143, 118, var(--tw-bg-opacity) );
}

.action-items-header-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.action-items__table-controls {
  justify-content: space-between;
  display: flex;
}

.action-items__mass-controls {
  flex-direction: row;
  display: flex;
}

.action-items__select-all {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  margin-right: .5rem;
  display: flex;
}

.action-items__select-all:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.action-items__select-all {
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-left: .5rem;
  padding-right: .5rem;
}

.action-items__select-all label {
  cursor: pointer;
  margin: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
  display: inline;
}

.action-items__select-all input {
  cursor: pointer;
}

.action-items-status-row__left span {
  margin-right: 1rem;
}

.action-items-status-row__right {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 1rem;
  display: grid;
}

.action-items-select-row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.action-items-container {
  width: 100%;
  margin-top: .5rem;
}

.action-item-row__select {
  margin: 0;
}

.action-item-row__question {
  min-width: 500px;
  font-size: .875rem;
  line-height: 1.25rem;
}

.action-item-row--condensed .action-item-row__question {
  min-width: 200px;
  max-width: 300px;
}

.action-item-row__category {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 233, 228, var(--tw-bg-opacity) );
  border-radius: .375rem;
  padding: .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

.action-item-row__date {
  min-width: 100px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.action-item-row__label {
  vertical-align: middle;
  align-items: center;
  display: flex;
}

.action-item-row__controls {
  text-align: right;
  vertical-align: middle;
  min-width: 230px;
}

.action-item-row__controls-wrapper {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.action-item-row__controls-wrapper button:first-of-type {
  margin-left: auto;
}

.action-item-row--condensed .action-item-row__controls {
  min-width: 0;
  width: 215px;
}

.action-items__closed-button-row {
  margin-left: auto;
}

.action-item-detail {
  grid-template-columns: 7fr 3fr;
  gap: 2rem;
  margin-top: 2rem;
  display: grid;
}

.action-item-detail__controls {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  border-radius: .25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 1.25rem;
  display: flex;
}

.action-item-detail__controls-row {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.action-item-detail__controls-row label {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  width: 120px;
  flex-shrink: 0;
}

.action-item-detail__publish-controls {
  flex-direction: column;
  gap: .5rem;
  margin-top: 1rem;
  display: flex;
}

.action-item-detail__publish-controls .button + .button {
  margin: 0;
}

.requested-info {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  display: flex;
}

.requested-info__row {
  flex-direction: row;
  gap: .5rem;
  display: flex;
}

.requested-info__input, .requested-info__textarea {
  width: 100%;
  --tw-border-opacity: 1;
  border-style: dashed;
  border-width: 1px;
  border-color: rgba(185, 190, 198, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
  border-radius: .25rem;
  padding: .75rem;
}

.action-item-row__misc-response-bubble {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 229, 232, var(--tw-bg-opacity) );
  border-radius: .375rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 1rem;
}

.manually-categorize-form__row {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.uncategorized-line-item-row__buttons {
  justify-content: flex-end;
  display: flex;
}

.uncategorized-transactions {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.uncategorized-transactions__header {
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.uncategorized-transactions__search {
  width: 250px;
}

.uncategorized-transactions__summary {
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.uncategorized-transactions__date-range {
  font-size: .75rem;
  line-height: 1rem;
}

.account-tags-block__header-controls {
  gap: .5rem;
  display: flex;
}

.uncategorized-transactions__footer-controls {
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  margin-top: 2rem;
  display: flex;
}

.uncategorized-transactions__footer {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  flex-direction: row;
  padding: 1rem;
  display: flex;
}

.institution {
  border-collapse: separate;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  border-radius: .75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.institution__header {
  gap: 1rem;
  margin: 1rem;
  display: flex;
}

.institution__icon {
  height: 3rem;
  width: 3rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  border-radius: .5rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.institution__info {
  flex-grow: 1;
  margin-top: auto;
  margin-bottom: auto;
}

.institution__name-and-tip {
  display: flex;
}

.institution__impact, .institution__label {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  font-size: .875rem;
  line-height: 1.25rem;
}

.institution-form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.institution-form__buttons {
  justify-content: flex-end;
  gap: 1rem;
  display: flex;
}

.connections-table__wrapper {
  margin-top: 1.5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.connections-table {
  table-layout: fixed;
}

.connections-table th {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
  padding: .5rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.connections-table td {
  padding: 0;
}

.connections-table__connection-row > td {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  padding: 1rem .5rem;
}

.connections-table__title {
  align-items: center;
  gap: .25rem;
  display: flex;
}

.connections-table__title.expandable {
  cursor: pointer;
}

.connections-table__caret {
  transform-origin: center;
  transition: transform .2s;
}

.connections-table__caret.expanded {
  transform: rotate(90deg);
}

.connections-table__title-column, .connections-table__api-key-column, .connections-table__status-column, .connections-table__edit-column {
  width: 25%;
}

.connections-table__account-name-column {
  border: none !important;
  padding: 0 !important;
}

.connections-table__account-name {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  box-shadow: inset 0 1px 0 var(--bg-gray-lighter);
  height: 0;
  color: #0000;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  transition: height .2s ease-out, color .2s ease-out;
  display: flex;
  overflow: hidden;
}

.connections-table__account-name.expanded {
  height: 73px;
  color: inherit;
}

.connections-table__after-account-names {
  margin-bottom: 0;
  transition: margin .2s ease-out;
}

.connections-table__after-account-names.expanded {
  margin-bottom: .5rem;
}

.connection-health-cell {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.connection-health-cell__symbol {
  flex-shrink: 0;
}

.modal__wrapper {
  border-radius: .5rem;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.modal__wrapper > * {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.modal__header {
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal__close {
  margin-left: auto;
}

.modal__body {
  max-height: 50vh;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
}

.modal__footer {
  margin: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transition: transform .2s, opacity .2s;
  transform: translateY(-50%);
  min-width: 400px !important;
  min-height: 0 !important;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
  transform: translateY(0);
}

.institution-form-errors li {
  --tw-text-opacity: 1;
  color: rgba(255, 27, 28, var(--tw-text-opacity) );
  font-size: .875rem;
  line-height: 1.25rem;
}

.connection-delete-confirmation__modal h2 {
  text-align: center;
}

.connection-delete-confirmation__buttons {
  display: flex;
}

.connection-delete-confirmation__button {
  flex-grow: 1;
}

.connection-edit-cell {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: .5rem;
  display: flex;
}

.connection-edit-cell__dropdown-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.connection-edit-cell__dropdown {
  margin-top: .5rem;
  padding: .25rem;
}

.connection-edit-cell__dropdown-menu-button {
  width: 100%;
  border-radius: .125rem;
}

.connection-edit-cell__dropdown-menu-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.connection-edit-cell__dropdown-menu-button {
  text-align: left;
  padding: .5rem;
}

.connection-edit-cell__dropdown-menu-button[disabled] {
  cursor: not-allowed;
}

.connection-edit-cell__dropdown-menu-button[disabled]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.connection-edit-cell__dropdown-menu-button[disabled] {
  opacity: .5;
}

.shopify-connection-form__shop-name-wrapper {
  align-items: center;
  gap: .25rem;
  display: flex;
}

.institutions__header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#root {
  height: 100%;
  width: 100%;
}

.app-view-container {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.page-header {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
}

.page-header img {
  margin-right: .5rem;
}

.section-header {
  --tw-text-opacity: 1;
  color: rgba(11, 15, 29, var(--tw-text-opacity) );
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
}

.section-header img {
  margin-right: .5rem;
}

input[type="text"].search-input {
  height: 3rem;
  background-image: url("search.92c5d018.svg");
  background-position: 4px;
  background-repeat: no-repeat;
  padding-left: 3rem;
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table th {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
}

.table td {
  font-size: .875rem;
  line-height: 1.25rem;
}

.table tr {
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
}

.table tbody tr:hover td {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.table td, .table th {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  padding: .75rem;
}

.table tr td:first-child, .table tr th:first-child {
  border-left-width: 1px;
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
  padding-left: 1rem;
}

.table tr td:last-child, .table tr th:last-child {
  border-right-width: 1px;
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
  padding-right: 1rem;
}

.table__pagination {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sidebar-form {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.sidebar-form__header {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  margin-bottom: 1rem;
  padding-bottom: 2rem;
}

.sidebar-form__body {
  flex-direction: column;
  display: flex;
}

.sidebar-form__submit-row {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 2.25rem;
  display: flex;
}

.sidebar-form__submit-row .button {
  width: 45%;
}

.Toastify__toast-container {
  width: 430px !important;
}

.Toastify__toast {
  --tw-shadow: 0px 2px 3px #7b838e1a !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  border-radius: .5rem !important;
}

.Toastify__toast-theme--light {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(230, 244, 241, var(--tw-bg-opacity) ) !important;
}

.autocomplete {
  width: 100%;
  flex-direction: column;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.autocomplete__row {
  border-radius: .375rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  padding: .5rem .75rem;
  display: flex;
}

.autocomplete__row--selected, .autocomplete__row--hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  border-color: currentColor;
}

html, body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
  font-family: Inter, Helvetica, Arial, sans-serif;
}

h1, h2, h3 {
  font-weight: 600;
}

h4, h5 {
  font-weight: 700;
}

h1 {
  font-size: 28px;
  line-height: 36px;
}

h2 {
  font-size: 20px;
  line-height: 28px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4 {
  font-size: 16px;
  line-height: 24px;
}

h5 {
  font-size: 12px;
  line-height: 18px;
}

a {
  --tw-text-opacity: 1;
  color: rgba(2, 143, 118, var(--tw-text-opacity) );
  text-decoration: none;
}

a:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 117, 97, var(--tw-text-opacity) );
  text-decoration: underline;
}

p {
  line-height: 1.5rem;
}

table {
  width: 100%;
}

th {
  text-align: left;
  font-weight: 700;
}

input[type="text"], input[type="email"], input[type="number"] {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 48px;
  border-radius: .5rem;
  padding: .75rem .5rem;
  display: block;
}

textarea {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: .75rem .5rem;
}

label {
  margin-bottom: .5rem;
  font-weight: 500;
  display: block;
}

select {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(227, 229, 232, var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
  --tw-shadow: 0px 2px 3px #7b838e1a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 48px;
  background-image: url("arrow-right-sm.9120a171.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  border-radius: .5rem;
  padding: .75rem 1.5rem .75rem .5rem;
  display: block;
}

input[type="text"]:invalid, input[type="email"]:invalid, input[type="number"]:invalid, input[type="text"]:user-invalid, input[type="email"]:user-invalid, input[type="number"]:user-invalid {
  --tw-border-opacity: 1;
  border-color: rgba(255, 27, 28, var(--tw-border-opacity) );
}

input[type="text"]:invalid, input[type="email"]:invalid, input[type="number"]:invalid, input[type="text"]:user-invalid, input[type="email"]:user-invalid, input[type="number"]:user-invalid {
  outline-color: red;
}

dl {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

dt, dd {
  margin: 0;
}

dl {
  display: flow-root;
}

dt, dd {
  float: left;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

dt {
  clear: left;
  margin-right: .5rem;
  font-weight: 600;
}

dd {
  clear: right;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.clear-none {
  clear: none;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.resize {
  resize: both;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5rem;
}

.justify-self-end {
  justify-self: end;
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity) );
}

.bg-green-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 244, 241, var(--tw-bg-opacity) );
}

.bg-red-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 232, 232, var(--tw-bg-opacity) );
}

.bg-gray-hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 248, 250, var(--tw-bg-opacity) );
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pr-4 {
  padding-right: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(2, 143, 118, var(--tw-text-opacity) );
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(255, 27, 28, var(--tw-text-opacity) );
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgba(241, 143, 1, var(--tw-text-opacity) );
}

.text-gray-dark {
  --tw-text-opacity: 1;
  color: rgba(114, 124, 140, var(--tw-text-opacity) );
}

.line-through {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(8px);
}

:root {
  --bg-black: #0b0f1d;
  --bg-white: #fff;
  --bg-green-default: #028f76;
  --bg-green-hover: #027561;
  --bg-green-dark: #163138;
  --bg-green-light: #cce9e4;
  --bg-green-lighter: #e6f4f1;
  --bg-blue-default: #3c7edb;
  --bg-blue-light: #d8e5f8;
  --bg-blue-lighter: #ecf2fb;
  --bg-red-default: #ff1b1c;
  --bg-red-light: #ffd1d2;
  --bg-red-lighter: #ffe8e8;
  --bg-orange-default: #f18f01;
  --bg-orange-light: #fc39cc;
  --bg-orange-lighter: #fef4e6;
  --bg-yellow-default: #ffc600;
  --bg-yellow-light: #fff4cc;
  --bg-yellow-lighter: #fff9e6;
  --bg-gray-default: #212535;
  --bg-gray-background: #f8f8fa;
  --bg-gray-hover: #f8f8fa;
  --bg-gray-dark: #727c8c;
  --bg-gray-light: #b9bec6;
  --bg-gray-lighter: #e3e5e8;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translateZ(var(--toastify-z-index) );
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    left: auto;
    right: 0;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:focus, .Toastify__close-button:hover {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  transform-origin: 100%;
  left: auto;
  right: 0;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--bottom-left, .Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--bottom-right, .Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--bottom-left, .Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--bottom-right, .Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--bottom-left, .Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--bottom-right, .Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-left, .Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--bottom-right, .Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

/*# sourceMappingURL=index.49527c86.css.map */
