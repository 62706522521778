.rule-form__header {
  @apply mb-4 pb-4 border-b border-gray-lighter;
}

.add-condition,
.reset-tags {
  @apply text-blue font-medium;
}

.reset-tags {
  @apply ml-auto;
}

.rule-form__row {
  @apply my-2;
}

.rule-form__row--flex {
  @apply flex flex-row;
}

.rule-form__radios {
  @apply flex flex-row gap-8;
}

.rule-condition__form {
  @apply mb-4 pb-4 border-b border-gray-lighter;
}

.rule-condition__form-row {
  @apply flex flex-row items-center my-2 justify-between gap-2;
}

.rule-condition__form-message {
  @apply text-red text-xs;
}

.rule-condition__label-wrap {
  @apply mt-4 flex flex-row items-center justify-between;
}

.rule-condition__label-wrap label {
  @apply m-0;
}

.rule-condition__type {
  min-width: 100px;
}

.rule-condition__select {
  @apply flex-grow-0 flex-shrink-0 text-xs;

  width: 100px;
}

.rule-condition__argument {
  @apply w-full overflow-hidden overflow-ellipsis;
}

input[type="text"].rule-condition__argument--invalid,
input[type="number"].rule-condition__argument--invalid {
  @apply border-red;

  outline-color: red;
}

.rule-form__line-item {
  @apply my-2 p-4 rounded-lg bg-blue-light text-sm;
}

.rule-form__line-item-info {
  @apply flex flex-row items-center justify-between mb-2 text-blue font-bold;
}
