.portfolio-tile {
  @apply p-6 rounded-lg border bg-white border-gray-lighter cursor-pointer shadow-flowfi;
}

.portfolio-tile h3,
.portfolio-tile h4 {
  @apply mr-4 overflow-hidden break-words;
}

.portfolio-tile__upper,
.portfolio-tile__lower {
  @apply flex flex-row items-center;
}

.portfolio-tile__upper {
  @apply pb-4;
}

.portfolio-tile__lower {
  @apply pt-4 border-t border-gray-light;
}
