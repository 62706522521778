.select-label {
  @apply text-sm;
}

.select-options {
  @apply mx-0 my-2;
}

.select-item {
  @apply cursor-pointer hover:bg-gray-lighter;
}

.select-item--selected {
  @apply text-green-hover;
}

.select-addon {
  @apply mt-2;
}
