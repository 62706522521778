.drawer {
  @apply w-1/3 h-flowfi-full bg-gray-background fixed top-0 right-0 z-20;

  transition: 0.3s transform ease-in-out;
}

.drawer--right {
  @apply top-0 right-0;

  transform: translateX(100%);
}

.drawer--left {
  @apply top-0 left-0;

  transform: translateX(-100%);
}

.drawer--open {
  transform: translateX(0);
}

.drawer__close {
  @apply flex items-center justify-center w-6 h-6 absolute top-4 right-4 cursor-pointer;
}

.drawer__content {
  @apply h-full pt-12 pb-8 px-6 flex flex-col;
}

.drawer__content--full-height {
  @apply h-full;
}

.drawer-overlay {
  @apply fixed top-0 left-0 w-flowfi-full h-flowfi-full bg-transparent z-10;

  pointer-events: none;
  transition: 0.5s background-color ease-in-out;
}

.drawer-overlay--visible {
  @apply block;

  pointer-events: all;
  background-color: rgba(11, 15, 29, 0.25);
}
