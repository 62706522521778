/*
EXPL for styles: React Datepicker comes loaded with a *very* opinionated stylesheet and Parcel keeps bundling its
packaged stylesheet in a way where we cannot simply write styles have them override cleanly. Thus, we will keep 
our styles out of Tailwind and use !important. 

Tailwind supports !important as an all-or-nothing option that I'd rather not turn on. The very latest version of it
supports inline decorating, e.g. `@apply !hidden`, but upgrading would also require auditing all other styles.
*/

input[type="text"].flowfi-datepicker {
  @apply pr-9 bg-no-repeat;

  background-position: calc(100% - 0.5rem) 50%;
  background-image: url("~/src/assets/icons/default/calendar.svg");
}

.flowfi-datepicker__popper {
  z-index: 50 !important;
}

/* React Datepicker direct overrides... */
.react-datepicker {
  overflow: hidden !important;
  border-radius: 0.5rem !important;
  border: 1px solid var(--bg-gray-lighter) !important;
  box-shadow: 0px 2px 3px rgba(123, 131, 142, 0.1) !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__navigation-icon {
  display: block !important;
  width: 32px !important;
  top: 0px !important;
}

.react-datepicker__navigation--previous {
  left: 0 !important;
}

.react-datepicker__navigation-icon--previous {
  right: 0 !important;
}

.react-datepicker__navigation--next {
  right: 0 !important;
}

.react-datepicker__navigation-icon--next {
  left: 0 !important;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  height: 32px !important;
}

.react-datepicker__navigation-icon--previous:before,
.react-datepicker__navigation-icon--next:before {
  width: 32px !important;
  height: 32px !important;
  border: none !important;
  background-repeat: no-repeat !important;
}

.react-datepicker__navigation-icon--previous:before {
  transform: none !important;
  top: 0 !important;
  right: 0 !important;
  background-image: url("~/src/assets/icons/default/arrow-left-sm.svg") !important;
  background-position: 50% 50% !important;
}

.react-datepicker__navigation-icon--next:before {
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  background-image: url("~/src/assets/icons/default/arrow-right-sm.svg") !important;
  background-position: 50% 50% !important;
}

.react-datepicker__header {
  background: var(--white) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom: none !important;
}

.react-datepicker__day-name {
  color: var(--bg-gray-dark) !important;
}

.react-datepicker__day--selected {
  color: white !important;
  background-color: var(--bg-green-default) !important;
}

.react-datepicker__day--outside-month {
  color: var(--bg-gray-light) !important;
}

.react-datepicker__month {
  margin-top: 0 !important;
}

.flowfi-datepicker__label--left + .react-datepicker-wrapper input {
  @apply rounded-l-none;
}

.flowfi-datepicker__wrapper--label-left {
  @apply flex flex-grow;
}

.flowfi-datepicker__label--left {
  @apply flex items-center px-4 m-0 rounded-l-md bg-gray-background border border-gray-lighter border-r-0 shadow-flowfi;
}
