/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: 430px !important;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  @apply rounded-lg shadow-flowfi !important;
}

/** handle the notification color and the text color based on the theme **/
.Toastify__toast-theme--light {
  @apply bg-green-lighter !important;
}
