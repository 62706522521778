.company-selector__trigger {
  @apply flex flex-row items-center text-sm;

  min-width: 250px;
}

.company-selector__trigger span {
  @apply pl-2;
}

.company-selector__label {
  @apply px-2 text-gray-light text-sm;
}

.company-selector__dropdown {
  @apply p-2;
}

.company-selector__dropdown-items {
  min-width: 250px;
}

.company-selector__dropdown-item {
  @apply my-0.5 px-2 py-1 flex flex-row items-center justify-between text-sm rounded hover:bg-gray-hover;
}

.company-selector__portfolio-link {
  @apply mx-0.5;
}
