.table {
  @apply my-4 border-separate;

  border-spacing: 0 8px;
}

.table th {
  @apply text-gray-dark;
}

.table td {
  @apply text-sm;
}

.table tr {
  @apply shadow-flowfi rounded-xl;
}

.table tbody tr:hover td {
  @apply bg-gray-hover;
}

.table td,
.table th {
  @apply p-3 bg-white border-t border-b border-gray-lighter;
}

.table tr td:first-child,
.table tr th:first-child {
  @apply border-l rounded-tl-xl rounded-bl-xl pl-4;
}

.table tr td:last-child,
.table tr th:last-child {
  @apply border-r rounded-tr-xl rounded-br-xl pr-4;
}

.table__pagination {
  @apply flex flex-row items-center justify-between;
}
