/*
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable.
*/

.account-tags-block__header {
  @apply flex gap-2 items-center justify-between mb-8 mt-4;
}

.account-tags-block__header-count {
  @apply text-orange;
}

.account-tags-block__header-controls {
  @apply flex gap-2;
}

input[type="text"].account-tags-block__search {
  width: 250px;
}

.account-tags-block__list {
  @apply flex flex-col gap-2 text-sm;
}

.account-tags-block__heading {
  @apply text-gray-dark font-bold py-3 text-base;
}

.account-tags-block__heading-col-account-name {
  @apply flex gap-2 items-center;
}

.account-tags-block__row {
  @apply flex flex-row items-center gap-2 px-4 rounded-xl;
}

.account-tags-block__edit-row {
  @apply flex flex-col gap-2;
}

.account-tags-block__row:not(.account-tags-block__heading) {
  @apply hover:bg-gray-hover;
}

.account-tags-block__row > * {
  @apply w-full;
}

.account-tags-block__col-account-name {
  max-width: 25%;
  min-width: 25%;
}

.account-tags-block__col-account-type {
  max-width: 20%;
  min-width: 20%;
}

.account-tags-block__col-buttons {
  @apply flex justify-end;

  max-width: 100px;
}

.account-tags-block__save-cancel {
  @apply flex justify-end;
}

.account-tags-block__footer {
  @apply mt-8 flex items-center justify-between gap-2;
}
