.learn-more {
  @apply flex items-center justify-center ml-2 relative cursor-pointer text-gray-dark text-xs rounded-full border border-gray-light;

  width: 16px;
  height: 16px;
}

.learn-more__tip {
  @apply absolute opacity-0 pointer-events-none transition-all;
  left: 80%;
}

.learn-more:hover > .learn-more__tip {
  @apply opacity-100 pointer-events-auto;
  left: 100%;
}

.learn-more__link {
  @apply block ml-2 p-2 rounded-md bg-black text-white hover:text-white hover:underline;
}
