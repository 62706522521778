.nav-tabs {
  @apply flex flex-row mt-6 mb-4 border-b border-gray-light;
}

.nav-tab {
  @apply block relative font-semibold mr-4 pb-5 text-gray-dark border-b-2 border-transparent no-underline hover:no-underline;

  top: 1px;
}

.nav-tab--active {
  @apply text-black border-b-2 border-green-hover;
}
