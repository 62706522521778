.side-nav-item {
  @apply flex flex-row items-center border-transparent border-l-2 font-semibold text-sm text-gray-dark;

  height: 56px;
}

.side-nav-item--active {
  @apply border-green border-l-2 text-green;
}

.side-nav-item__icon {
  @apply flex px-2 justify-center items-center h-full;

  width: 70px;
  padding-left: calc(0.5rem - 2px);
}

.side-nav-item__icon img {
  @apply block;

  width: 26px;
  height: 26px;
}

.side-nav-item--active .side-nav-item__icon {
  @apply bg-green-dark;
}

.side-nav-item__label {
  @apply flex items-center h-full pl-4 pr-2;
}

button.side-nav-item {
  @apply hover:text-green-hover;
}
