.institution {
  @apply my-4 border-separate bg-white rounded-xl border border-gray-lighter;
}

.institution__header {
  @apply flex gap-4 m-4;
}

.institution__icon {
  @apply flex justify-center items-center rounded-lg border border-gray-lighter h-12 w-12 flex-shrink-0;
}

.institution__info {
  @apply flex-grow my-auto;
}

.institution__name-and-tip {
  @apply flex;
}

.institution__impact {
  @apply text-gray-dark text-sm;
}

.institution__label {
  @apply text-sm text-gray-dark;
}

.institution-form {
  @apply flex flex-col gap-4;
}

.institution-form__buttons {
  @apply flex gap-4 justify-end;
}
