.table-paginator__button {
  @apply h-full px-3 flex items-center justify-center text-gray-dark hover:bg-gray-hover;
}

.table-paginator__button:nth-child(n + 2) {
  @apply border-l border-gray-lighter;
}

.table-paginator__button--active {
  @apply text-green font-bold;
}

.paginator__pages {
  @apply p-0 flex;
}
