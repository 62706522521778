.line-item-summary {
  @apply my-2 p-4 rounded-lg bg-blue-lighter text-sm;
}

.line-item-summary dl {
  @apply m-0;
}

.line-item-summary dt,
.line-item-summary dd {
  @apply my-1;
}

.line-item-summary dt {
  @apply text-blue font-bold;
}
