/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

.tag {
  @apply flex gap-1 py-1 px-2 text-xs rounded-lg whitespace-nowrap;
}

.tag--global {
  @apply bg-green-light;
}

.tag--local {
  @apply bg-gray-lighter;
}

.tag--selected {
  outline: 2px solid var(--bg-yellow-default);
}
