.connection-edit-cell {
  @apply flex justify-end gap-2 flex-wrap;
}

.connection-edit-cell__dropdown-icon {
  @apply mx-1 h-6 w-6;
}

.connection-edit-cell__dropdown {
  @apply mt-2 p-1;
}

.connection-edit-cell__dropdown-menu-button {
  @apply w-full p-2 rounded-sm text-left hover:bg-gray-hover;
}

.connection-edit-cell__dropdown-menu-button[disabled] {
  @apply opacity-50 cursor-not-allowed hover:bg-white;
}
