.autocomplete {
  @apply flex flex-col text-sm w-full;
}

.autocomplete__row {
  @apply flex flex-row gap-2 items-center justify-between px-3 py-2 rounded-md;
}

.autocomplete__row--selected {
  @apply bg-gray-hover;
}

.autocomplete__row--hover {
  @apply hover:bg-gray-hover;
}
