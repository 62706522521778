@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/* Core/non-component style imports */
@import "./site.css";
@import "./form-elements.css";
@import "./table.css";
@import "./sidebar-form.css";
@import "./toast.css";
@import "./autocomplete.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply w-flowfi-full h-flowfi-full overflow-hidden;
  }

  body {
    @apply bg-gray-background;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
  }

  h1,
  h2,
  h3 {
    @apply font-semibold;
  }

  h4,
  h5 {
    @apply font-bold;
  }

  h1 {
    font-size: 28px;
    line-height: 36px;
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
  }

  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  h5 {
    font-size: 12px;
    line-height: 18px;
  }

  a {
    @apply text-green no-underline;
  }

  a:hover {
    @apply text-green-hover underline;
  }

  p {
    @apply leading-6;
  }

  table {
    @apply w-full;
  }

  th {
    @apply font-bold text-left;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"] {
    @apply w-full block px-2 py-3 bg-white border border-gray-lighter rounded-lg shadow-flowfi;

    height: 48px;
  }

  textarea {
    @apply w-full px-2 py-3 bg-white border border-gray-lighter rounded-lg shadow-flowfi;
  }

  label {
    @apply block mb-2 font-medium;
  }

  select {
    @apply appearance-none block pl-2 pr-6 py-3 bg-white border border-gray-lighter rounded-lg shadow-flowfi bg-no-repeat;

    height: 48px;
    background-image: url("~/src/assets/icons/default/arrow-right-sm.svg");
    background-position: 100% 50%;
  }

  input[type="text"]:invalid,
  input[type="password"]:invalid,
  input[type="email"]:invalid,
  input[type="number"]:invalid,
  input[type="text"]:user-invalid,
  input[type="password"]:user-invalid,
  input[type="email"]:user-invalid,
  input[type="number"]:user-invalid {
    @apply border-red;

    outline-color: red;
  }

  dl {
    @apply my-4;
  }

  dt,
  dd {
    @apply m-0;
  }

  dl {
    @apply flow-root;
  }

  dt,
  dd {
    @apply float-left my-2;
  }

  dt {
    @apply mr-2 clear-left font-semibold;
  }

  dd {
    @apply clear-right;
  }
}

/* Theme colors as CSS variables - useful when Tailwind can't style a library properly */
:root {
  --bg-black: #0b0f1d;
  --bg-white: #fff;

  --bg-green-default: #028f76;
  --bg-green-hover: #027561;
  --bg-green-dark: #163138;
  --bg-green-light: #cce9e4;
  --bg-green-lighter: #e6f4f1;

  --bg-blue-default: #3c7edb;
  --bg-blue-light: #d8e5f8;
  --bg-blue-lighter: #ecf2fb;

  --bg-red-default: #ff1b1c;
  --bg-red-light: #ffd1d2;
  --bg-red-lighter: #ffe8e8;

  --bg-orange-default: #f18f01;
  --bg-orange-light: #fc39cc;
  --bg-orange-lighter: #fef4e6;

  --bg-yellow-default: #ffc600;
  --bg-yellow-light: #fff4cc;
  --bg-yellow-lighter: #fff9e6;

  --bg-gray-default: #212535;
  --bg-gray-background: #f8f8fa;
  --bg-gray-hover: #f8f8fa;
  --bg-gray-dark: #727c8c;
  --bg-gray-light: #b9bec6;
  --bg-gray-lighter: #e3e5e8;
}
