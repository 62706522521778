.login-column {
  @apply p-4 h-flowfi-full flex flex-col justify-center items-start;
}

.login-header {
  @apply mx-auto w-3/4;
}

.login-title {
  @apply font-bold text-7xl mb-8;

  line-height: 80px;
}

.login-text {
  @apply mt-5 mb-10;
}

.login__image {
  @apply w-full h-full rounded-lg bg-green-light bg-no-repeat;

  background-size: 80%;
  background-position: 100% 50%;
  background-image: url("~/src/assets/images/login-image.svg");
}
