.sidebar-form {
  @apply h-full flex flex-col;
}

.sidebar-form__header {
  @apply mb-4 pb-8 border-b border-gray-lighter;
}

.sidebar-form__body {
  @apply flex flex-col;
}

.sidebar-form__submit-row {
  @apply mt-auto pt-9 border-t border-gray-lighter flex flex-row items-center justify-between;
}

.sidebar-form__submit-row .button {
  width: 45%;
}
