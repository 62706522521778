.scroll-container {
  @apply overflow-x-hidden overflow-y-auto p-4;

  -webkit-overflow-scrolling: touch;
}

.scroll-container--full-height {
  @apply h-full;
}

.scroll-container--no-padding {
  @apply p-0;
}
