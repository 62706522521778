.company-profile {
  @apply mt-8 grid grid-cols-flowfi-two-reverse gap-4;
}

.company-profile__meta {
  @apply flex flex-col items-center justify-center;
}

.company-profile__logo {
  @apply flex flex-col items-center justify-center w-full p-5 bg-gray-background rounded border border-gray-lighter;
}

.company-profile__name {
  @apply my-2;
}

.company-profile__email {
  @apply inline-flex text-gray-dark text-sm;
}

.company-profile__email img {
  @apply w-4 mr-1;
}

.company-profile__form {
  @apply m-6;
}

.company-profile__form h2 {
  @apply mb-6;
}

.company-profile__form-row {
  @apply py-4 grid grid-cols-2 gap-4;
}

.company-profile__form-row input {
  @apply w-full;
}

.company-profile__form-row:first-of-type {
  @apply border-t border-gray-lighter;
}

.company-profile__form-buttons {
  @apply mt-2 pt-6 flex justify-between border-t border-gray-lighter;
}
