.toast {
  @apply flex flex-row items-center gap-2;
}

.toast__body {
  @apply flex flex-col;
}

.toast__icon {
  @apply flex items-center;
}

.toast__message {
  @apply text-black text-sm leading-6;
}

.toast__children {
}
