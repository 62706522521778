.rich-text {
  @apply border border-gray-light rounded-lg shadow-flowfi;
}

.rich-text__toolbar {
  @apply flex flex-row border-b border-gray-light;
}

.rich-text__controls {
  @apply m-1 flex flex-row;
}

.rich-text__controls + .rich-text__controls {
  @apply border-l border-gray-lighter;
}

.rich-text__button {
  @apply mx-0.5 flex items-center justify-center p-1 border border-transparent rounded hover:bg-gray-lighter;
}

.rich-text__button--active {
  @apply border-gray-light bg-gray-lighter;
}

.rich-text__button--disabled {
  @apply opacity-50 pointer-events-none;
}

.rich-text__editor {
  @apply p-2;
}

/* output */
.rich-text__editor blockquote,
.rich-text-output blockquote {
  @apply p-2 my-4 bg-gray-lighter border-l-2 border-gray-light text-gray-dark;
}

.rich-text-output ol,
.rich-text-output ul {
  @apply my-4 pl-4;
}

.rich-text-output ol {
  @apply list-decimal;
}

.rich-text-output ul {
  @apply list-disc;
}
