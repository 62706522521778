.action-items__create-trigger {
  @apply inline-flex h-12 text-base items-center font-semibold border rounded-lg px-3 flex-shrink-0 text-white border-green bg-green hover:bg-green-hover shadow-flowfi;
}

.action-items__tabs {
  @apply flex flex-row mt-6 mb-8 border-b border-gray-light;
}

.action-items__tab {
  @apply appearance-none flex flex-row items-center relative font-semibold mr-6 pb-2 text-gray-dark border-b-2 border-transparent no-underline hover:no-underline;

  top: 1px;
}

.action-items__tab--active {
  @apply text-black border-b-2 border-green-hover;
}

.action-items-tab__pip {
  @apply w-2 h-2 ml-2 rounded-full bg-gray-light;
}

.action-items-tab__pip--green {
  @apply bg-green;
}

.action-items-header-row {
  @apply flex flex-row mb-4 items-center justify-between;
}

.action-items__table-controls {
  @apply flex justify-between;
}

.action-items__mass-controls {
  @apply flex flex-row;
}

.action-items__select-all {
  @apply bg-white mr-2 px-2 flex flex-row items-center border border-gray-lighter rounded-lg shadow-flowfi cursor-pointer hover:bg-gray-background;
}

.action-items__select-all label {
  @apply inline m-0 text-sm font-normal cursor-pointer;
}

.action-items__select-all input {
  @apply cursor-pointer;
}

.action-items-status-row__left span {
  @apply mr-4;
}

.action-items-status-row__right {
  @apply grid grid-cols-3 mb-4;
}

.action-items-select-row {
  @apply grid grid-cols-2;
}

.action-items-container {
  @apply w-full mt-2;
}

.action-item-row__select {
  @apply m-0;
}

.action-item-row__question {
  @apply text-sm;

  min-width: 500px;
}

.action-item-row--condensed .action-item-row__question {
  min-width: 200px;
  max-width: 300px;
}

.action-item-row__category {
  @apply p-2 bg-green-light rounded-md text-xs;
}

.action-item-row__date {
  @apply font-bold text-sm;

  min-width: 100px;
}

.action-item-row__label {
  @apply flex align-middle items-center;
}

.action-item-row__controls {
  @apply align-middle text-right;

  min-width: 230px;
}

.action-item-row__controls-wrapper {
  @apply flex flex-row items-center;
}

.action-item-row__controls-wrapper button:first-of-type {
  @apply ml-auto;
}

.action-item-row--condensed .action-item-row__controls {
  min-width: 0;
  width: 215px;
}

.action-items__closed-button-row {
  @apply ml-auto;
}

/* details */
.action-item-detail {
  @apply grid grid-cols-flowfi-two gap-8 mt-8;
}

.action-item-detail__controls {
  @apply flex flex-col gap-2 items-center justify-center w-full p-5 rounded bg-gray-background border border-gray-lighter;
}

.action-item-detail__controls-row {
  @apply w-full flex flex-row items-center justify-between;
}

.action-item-detail__controls-row label {
  @apply text-gray-dark flex-shrink-0;

  width: 120px;
}

.action-item-detail__publish-controls {
  @apply flex flex-col mt-4 gap-2;
}

.action-item-detail__publish-controls .button + .button {
  @apply m-0;
}

/* requested info */
.requested-info {
  @apply flex flex-col gap-4 mt-6 pt-6 border-t border-gray-lighter;
}

.requested-info__row {
  @apply flex flex-row gap-2;
}

.requested-info__input {
  @apply w-full p-3 rounded bg-gray-lighter border border-dashed border-gray-light;
}

.requested-info__textarea {
  @apply w-full p-3 rounded bg-gray-lighter border border-dashed border-gray-light;
}

.action-item-row__misc-response-bubble {
  @apply p-4 mt-2 mb-2 bg-gray-lighter rounded-md;
}
