/* 
  Tool to convert Hexadecimal to filter values: https://codepen.io/sosuke/pen/Pjoqqp 
  Since our icons aren't flat black, the first two properties in the filter make it so.
*/

.svg {
  background-color: transparent;
}

.svg-icon {
  opacity: 0.9;
}

/* TODO: These all need an accuracy pass. Non-gray/green items need to get adjusted to have transparency suppport */
.svg--gray {
  /* Pure HEX translation */
  /* filter: brightness(0) saturate(100%) invert(52%) sepia(19%) hue-rotate(177deg) brightness(88%) contrast(86%); */
  /* Eyeballed. */
  filter: grayscale(65%) hue-rotate(30deg) saturate(338%) brightness(88%)
    contrast(86%);
}

.svg--blue {
  /* Pure HEX translation */
  filter: brightness(0) saturate(100%) invert(42%) sepia(82%) saturate(2092%)
    hue-rotate(199deg) brightness(92%) contrast(85%);
}

.svg--green {
  /* Pure HEX translation */
  /* filter: brightness(0) saturate(100%) invert(38%) sepia(74%) saturate(4608%) hue-rotate(152deg) brightness(91%) contrast(98%); */
  /* Eyeballed. */
  filter: sepia(100%) hue-rotate(116deg) saturate(340%) brightness(70%)
    contrast(91%);
}

.svg--red {
  /* Pure HEX translation */
  filter: brightness(0) saturate(100%) invert(32%) sepia(73%) saturate(6362%)
    hue-rotate(347deg) brightness(125%) contrast(130%);
}

.svg--orange {
  /* Pure HEX translation */
  filter: brightness(0) saturate(100%) invert(58%) sepia(22%) saturate(6369%)
    hue-rotate(8deg) brightness(104%) contrast(99%);
}

.svg--yellow {
  /* Pure HEX translation */
  filter: brightness(0) saturate(100%) invert(70%) sepia(84%) saturate(1260%)
    hue-rotate(360deg) brightness(104%) contrast(104%);
}
