.uncategorized-transactions {
  @apply h-full flex flex-col overflow-hidden;
}

.uncategorized-transactions__header {
  @apply flex gap-2 items-center justify-between my-8;
}

.uncategorized-transactions__search {
  width: 250px;
}

.uncategorized-transactions__summary {
  @apply font-bold mb-1 text-sm;
}

.uncategorized-transactions__date-range {
  @apply text-xs;
}

.account-tags-block__header-controls {
  @apply flex gap-2;
}

.uncategorized-transactions__footer-controls {
  @apply mt-8 flex items-center justify-between gap-2;
}

.uncategorized-transactions__footer {
  @apply bg-white flex flex-row p-4 border-t border-gray-lighter;
}
