#root {
  @apply w-full h-full;
}

.app-view-container {
  @apply h-flowfi-full flex flex-col;
}

.page-header {
  @apply flex flex-row gap-1 text-black text-2xl items-center;
}

.page-header img {
  @apply mr-2;
}

.section-header {
  @apply flex flex-row gap-1 text-black text-xl items-center;
}

.section-header img {
  @apply mr-2;
}
