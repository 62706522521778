.connections-table__wrapper {
  @apply mx-2 mt-6;
}

.connections-table {
  @apply table-fixed;
}

.connections-table th {
  @apply p-2 text-sm font-normal text-gray-dark;
}

.connections-table td {
  @apply p-0;
}

.connections-table__connection-row > td {
  @apply px-2 py-4 border-t border-gray-lighter;
}

.connections-table__title {
  @apply flex items-center gap-1;
}

.connections-table__title.expandable {
  @apply cursor-pointer;
}

.connections-table__caret {
  transform-origin: center center;
  transition: 0.2s transform;
}

.connections-table__caret.expanded {
  transform: rotate(90deg);
}

.connections-table__title-column {
  @apply w-1/4;
}

.connections-table__api-key-column {
  @apply w-1/4;
}

.connections-table__status-column {
  @apply w-1/4;
}

.connections-table__edit-column {
  @apply w-1/4;
}

.connections-table__account-name-column {
  padding: 0 !important;
  border: none !important;
}

.connections-table__account-name {
  @apply bg-gray-background overflow-hidden w-full px-2 flex items-center;

  box-shadow: inset 0 1px 0 var(--bg-gray-lighter);

  height: 0;
  color: transparent;
  transition: height 0.2s ease-out, color 0.2s ease-out;
}

.connections-table__account-name.expanded {
  height: 73px;
  color: inherit;
}

.connections-table__after-account-names {
  @apply mb-0;
  transition: margin 0.2s ease-out;
}

.connections-table__after-account-names.expanded {
  @apply mb-2;
}
