.company-homepage {
  @apply mt-8 grid gap-4;

  grid-template-columns: minmax(0, 7fr) 3fr;
}

.company-profile-info-box {
  @apply text-sm;
}

.company-profile-info-box__header {
  @apply grid grid-cols-flowfi-two-reverse gap-2 p-3 bg-gray-background rounded-md border border-gray-lighter;
}

.company-profile__meta + .button {
  @apply mt-2;
}

.company-profile-info-box__header-details {
  @apply flex items-center;
}

.company-profile-info-box__row {
  @apply m-1 grid grid-cols-2 gap-2 p-2;
}

.company-profile-info-box__row .text-left {
  @apply text-gray-dark;
}

.subheader-row {
  @apply pb-4 mb-4 flex flex-row items-center justify-between border-b border-gray-lighter;
}
