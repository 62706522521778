.connection-delete-confirmation__modal h2 {
  @apply text-center;
}

.connection-delete-confirmation__buttons {
  @apply flex;
}

.connection-delete-confirmation__button {
  @apply flex-grow;
}
