.account-block-card {
  @apply cursor-pointer;
}

.account-block-card__inner {
  @apply p-4;
}

.account-block-card__heading {
  @apply flex items-center;
}

.account-block-card__icon {
  @apply flex items-center justify-center mr-4 bg-green-lighter rounded-md h-14 w-14 flex-shrink-0;
}

.account-block-card__icon--red {
  @apply bg-red-lighter;
}

.account-block-card__name {
  @apply break-words min-w-0;
}

.account-block-card__divider {
  @apply border-b border-gray-lighter my-6;
}

.account-block-card__insights {
  @apply flex flex-col gap-2 text-sm;
}

.account-block-card-insight {
  @apply flex gap-2 justify-between items-end;
}

.account-block-card-insight__label {
  @apply text-gray-dark;
}
