.flowfi-sidebar {
  @apply h-flowfi-full flex flex-col bg-black bg-repeat-y text-white;

  background-image: url("~/src/assets/images/gray-tile.png");
  background-position: 72px 0;
}

.flowfi-sidebar--collapsed {
  background-image: none;
}

.flowfi-sidebar__mode-toggle {
  @apply self-end;
}
