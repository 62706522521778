.rules-page-header {
  @apply flex flex-row items-center;
}

input[type="text"].rule-filter {
  @apply ml-auto;

  width: 250px;
}

.rule-condition {
  @apply leading-6;
}

.rule-condition__operator {
  @apply text-gray-dark;
}

.rules-table {
}

.rules-table__conditions {
  max-width: 350px;
}

.rules-table__controls {
  @apply text-right;

  min-width: 230px;
}
