.flowfi-grid {
  @apply grid;
}

.flowfi-login {
  @apply grid-cols-flowfi-login mx-auto gap-0 w-flowfi-full h-flowfi-full;
}

.flowfi-base {
  @apply grid-cols-flowfi-base overflow-hidden w-flowfi-full h-flowfi-full;
}

.flowfi-two {
  @apply grid-cols-flowfi-two w-flowfi-full h-flowfi-full;
}

.flowfi-three {
  @apply grid-cols-flowfi-three w-flowfi-full h-flowfi-full;
}

.flowfi-condensed {
  @apply grid-cols-flowfi-condensed w-flowfi-full h-flowfi-full;
}

.flowfi-bare {
  @apply flex flex-col w-flowfi-full h-flowfi-full;
}

/* Responsive grid */
.responsive-grid-four {
  @apply grid grid-cols-2 gap-4 lg:grid-cols-4;
}
