/* 
  NOTE(2022-07-06): "CoA Mappings" or "Mappings" used to be called "Account Tags", or "Tags". 
  References to "Tags" remain prevalent in the code; consider the terms interchangeable. 
*/

.tags {
  @apply flex flex-row items-center overflow-hidden;
}

.tags--show-more {
  @apply flex-wrap;
}

.tags--edit {
  @apply overflow-visible;
}

.tags__form {
  @apply flex-grow;
}

.tags .tags__input {
  @apply w-full text-sm outline-none;
}

.tags__truncate-button {
  @apply text-xs text-blue whitespace-nowrap;
}

.tags__tag-wrapper {
  @apply invisible;
}

.tags__tag-wrapper--visible {
  @apply visible;
}
