.modal__wrapper {
  @apply flex flex-col rounded-lg overflow-hidden;
}

.modal__wrapper > * {
  @apply bg-white;
}

.modal__header {
  @apply flex flex-row items-start p-4;
}

.modal__close {
  @apply ml-auto;
}

.modal__body {
  /* modal body gains padding from the ScrollContainer */
  @apply p-0 flex-grow flex-shrink;

  max-height: 50vh;
}

.modal__footer {
  @apply m-0;
}

.ReactModal__Overlay {
  @apply opacity-0;

  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  @apply opacity-100;
}

.ReactModal__Overlay--before-close {
  @apply opacity-0;
}

.ReactModal__Content {
  @apply opacity-0;

  transition: 0.2s transform, 0.2s opacity;
  transform: translateY(-50%);
  min-width: 400px !important;
  min-height: 0 !important;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  @apply opacity-100;

  transform: translateY(0);
}
