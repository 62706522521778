.load-transactions {
  @apply p-6;
}

.load-transactions__header {
  @apply flex items-center gap-4;
}

.load-transactions__form {
  @apply flex gap-4 items-center justify-between;
}

.load-transactions__date-pickers {
  @apply flex gap-4 items-center;
}

.load-transactions__drawer {
  @apply flex flex-col justify-between h-full;
}

.load-transactions__drawer-insights {
  @apply flex flex-col items-center;
}

.load-transaction__break {
  @apply border-b border-gray-lighter my-6;
}
